import {
    ReactIntegration,
    createReactRouterV6DataOptions,
    getWebInstrumentations,
    initializeFaro,
} from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { matchRoutes } from 'react-router-dom'
import { getLocaleFromLocalStorage } from './app/commons/toolsbox/getLocaleFromLocalStorage'
import { obfuscateEvent } from './app/commons/toolsbox/logger/logger'
import { Lang, defaultLocale } from 'src/tools/Helpers/Constants/Locale'

const initialLang = getLocaleFromLocalStorage()?.lang || defaultLocale.lang

const initMSW = async () => {
    if (import.meta.env.VITE_ENV === 'mock') {
        return import('./mocks/browser').then((module) => {
            module.worker.start({
                onUnhandledRequest: 'warn',
            })
            // eslint-disable-next-line no-console
            console.log(
                'MSW handlers',
                module.worker
                    .listHandlers()
                    .map((handler) => handler.info.header)
            )
        })
    } else {
        return Promise.resolve()
    }
}

const loadConfig = async () => {
    try {
        const response = await fetch('/config.json')
        if (!response.ok) {
            window.hasRuntimeEnv = false
        }
        window.hasRuntimeEnv = true
        window.appRuntimeEnv = await response.json()
    } catch (error) {
        window.hasRuntimeEnv = false
    }
}

const InitializeLanguageBeforeConstantsLoading = async (lang: Lang) => {
    await i18n.use(initReactI18next).init({
        lng: lang, // if you're using a language detector, do not define the lng option
        fallbackLng: defaultLocale.lang,
        debug: false,
        nsSeparator: false,
        keySeparator: '.',
        returnEmptyString: false,
    })
}

loadConfig()
    .then(() => initMSW())
    .then(() => InitializeLanguageBeforeConstantsLoading(initialLang))
    .then(() => {
        import('./app').then((module) => module.default())
    })

const GRAFANA_CLOUD_URL =
    'https://faro-collector-prod-eu-north-0.grafana.net/collect/1ba97eb70a5ed734547270a93771d7b5'
const GRAFANA_CLOUD_APP_NAME = 'Practitioners UI'
const GRAFANA_ENV_MAPPING: Record<string, string> = {
    dev: 'dev',
    release: 'staging',
    production: 'production',
}

initializeFaro({
    // we cannot send dev log to grafana because of CORS configured in the grafana app
    // if this is needed, create your own Grafana Cloud instance and change GRAFANA_CLOUD_URL
    paused: import.meta.env.DEV === true,
    url: GRAFANA_CLOUD_URL,
    app: {
        name: GRAFANA_CLOUD_APP_NAME,
        version: import.meta.env.VITE_VERSION,
        environment: GRAFANA_ENV_MAPPING[import.meta.env.VITE_ENV] ?? 'dev',
    },
    beforeSend: obfuscateEvent,
    sessionTracking: {
        session: {
            attributes: {
                user_id: 'not_logged',
            },
        },
    },
    instrumentations: [
        // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
        ...getWebInstrumentations(),

        // Initialization of the tracing package.
        // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
        new TracingInstrumentation(),
        new ReactIntegration({
            router: createReactRouterV6DataOptions({
                matchRoutes,
            }),
        }),
    ],
})
